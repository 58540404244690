<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    const query = this.$route.query;
    this.$router.push({
      path: "/" + query.key,
      query: { q: query.q, search: query.search }
    });
  }
};
</script>
<style></style>
